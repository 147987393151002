import React, { Component } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import Orders from "./components/orders/Orders";
import Routes from "./components/routes/Routes";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import Footer from "./components/footer/footer";

import "./App.scss";
import "./modal.scss";
import { isDev } from "./config";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: [],
      component: [
        {
          key: "initial",
        },
      ],
      modal: false,
    };
    this.timer = null;
    this.pageswitch = this.pageswitch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.removeSetting = this.removeSetting.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async componentDidMount() {
    await this.loadSettings();

    let arr = [
      {
        component: <Orders next={this.pageswitch} color="green" />,
        key: "ordergreen",
        color: "green",
      },
      {
        component: <Routes next={this.pageswitch} color="green" />,
        key: "routegreen",
        color: "green",
      },
    ];

    if (
      this.state.component.length === 1 &&
      this.state.component[0].key === "initial"
    ) {
      this.setState({ component: arr });
    }
    //this.pageswitch();
    document.addEventListener("keydown", this.settings.bind(this), false);
    //this.saveSettings();
    let intervalId = setInterval(
      this.pageReload.bind(this),
      1000 * 60 * 60 * 12
    );
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.state.intervalId);
    document.removeEventListener("keydown", this.settings.bind(this), false);
  }

  settings(e) {
    if (e.key === "Enter" && e.shiftKey) {
      this.toggle();
    }
  }

  async loadSettings() {
    const set = window.localStorage.getItem("settings");
    let settings = [];
    if (set) {
      this.setState({ settings: JSON.parse(set) }, () => {
        // eslint-disable-next-line array-callback-return
        this.state.settings.map((val, idx) => {
          if (val.component > "") {
            let component = [];
            component.key = val.component + val.color + val.componentName;
            component.color = val.color;
            switch (val.component) {
              case "order":
                component.component = (
                  <Orders
                    next={this.pageswitch}
                    color={val.color}
                    filter={val.filter}
                  />
                );
                break;
              case "route":
                component.component = (
                  <Routes next={this.pageswitch} color={val.color} />
                );
                break;
              default:
            }
            settings.push(component);
          }
        });
        if (settings.length === 0) {
          settings = [
            {
              key: "initial",
            },
          ];
        }
        this.setState({ component: settings });
      });
    }
  }

  saveSettings(e) {
    e.preventDefault();
    let state = this.state.settings;
    function setSettings(_callback) {
      window.localStorage.setItem("settings", JSON.stringify(state));
      _callback();
    }
    setSettings(() => window.location.reload());
  }

  pageReload() {
    window.location.reload();
  }

  handleChange = (e) => {
    let box = e.target.getAttribute("rel");
    let settings = [...this.state.settings];
    let data = e.target.options[e.target.selectedIndex].dataset.selected;
    let filtrate =
      e.target.options[e.target.selectedIndex].dataset.filter || "";
    if (box === "component") {
      settings[e.target.dataset.id]["filter"] = filtrate;
      settings[e.target.dataset.id]["componentName"] = e.target.value;
    }
    settings[e.target.dataset.id][box] = data;
    console.log(settings);
    this.setState({ settings });
  };
  addScreen = (e) => {
    this.setState((prevState) => ({
      settings: [
        ...prevState.settings,
        { component: "", color: "", filter: "", componentName: "" },
      ],
    }));
  };

  screens() {
    let items = [<option key="" value="" />];
    items.push(
      <option key={"order-alles"} value={"all"} data-selected="order">
        Order - alles
      </option>
    );
    items.push(
      <option
        key={"order-webwinkel"}
        value={"Webwinkel"}
        data-filter="Webwinkel"
        data-selected="order">
        Order - webwinkel
      </option>
    );
    items.push(
      <option
        key={"order-congress"}
        value={"Congress"}
        data-filter="Congress"
        data-selected="order">
        Order - congress
      </option>
    );
    items.push(
      <option key={"route"} value={"route"} data-selected="route">
        Route
      </option>
    );
    return items;
  }

  colors() {
    let items = [<option key="" value="" />];
    items.push(
      <option key={"green"} value={"green"} data-selected="green">
        Groen
      </option>
    );
    items.push(
      <option key={"blue"} value={"blue"} data-selected="blue">
        Blauw
      </option>
    );
    return items;
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  removeSetting(e) {
    e.preventDefault();
    let items = this.state.settings;
    const removeItem = (items, i) => items.splice(i, 1);
    removeItem(items, e.target.getAttribute("data-id"));
    this.setState({ settings: items });
  }

  pageswitch(next) {
    let ara = this.state.component;
    if (next) {
      ara.push(ara.splice(0, 1)[0]);
      this.setState({ show: ara });
    } else {
      ara.unshift(ara.pop());
      this.setState({ show: ara });
    }
  }

  render() {
    let { settings } = this.state;
    return (
      <React.Fragment>
        {isDev && (
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              gap: 4,
              background: "white",
              padding: 6,
              borderBottomRightRadius: "12px",
            }}>
            <span className="iconify" data-icon="logos:react"></span>
            <span style={{ fontSize: "0.8rem" }}>development modus</span>
          </div>
        )}
        <CSSTransitionGroup
          transitionName="shift"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
          component="div">
          <React.Fragment key={this.state.component[0].key}>
            {this.state.component[0].component}
          </React.Fragment>
        </CSSTransitionGroup>
        {<Footer />}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Settings</ModalHeader>
          <ModalBody>
            <form onSubmit={this.saveSettings} onChange={this.handleChange}>
              <button
                onClick={this.addScreen}
                type="button"
                className="btn btn-outline-primary mb-3">
                Add new screen
              </button>
              {settings.map((val, idx) => {
                let setting = `scherm-${idx}`,
                  color = `kleur-${idx}`;
                return (
                  <div key={idx} className="form-row">
                    <div className="col-6 form-group">
                      <label htmlFor={setting[idx]}>{`scherm #${
                        idx + 1
                      }`}</label>
                      <select
                        name={setting}
                        data-id={idx}
                        id={setting}
                        rel="component"
                        defaultValue={settings[idx].componentName}
                        key={"com" + settings[idx]}
                        className="form-control">
                        {this.screens()}
                      </select>
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor={color[idx]}>Kleur</label>
                      <select
                        name={color}
                        data-id={idx}
                        id={color}
                        rel="color"
                        defaultValue={settings[idx].color}
                        key={"col" + settings[idx]}
                        className="form-control">
                        {this.colors()}
                      </select>
                    </div>
                    <div className="closeSetting">
                      <button
                        type="button"
                        className="close"
                        data-id={idx}
                        onClick={this.removeSetting}>
                        ×
                      </button>
                    </div>
                  </div>
                );
              })}
              <input type="submit" value="Submit" className="btn btn-success" />
            </form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default App;
