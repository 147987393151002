import React, { Component } from "react";
import { ReactComponent as Checkmark } from "./checkmark.svg";
class Newline extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, check: false, props: this.props };
  }
  componentDidMount() {
    let intervalId = setInterval(this.setshow.bind(this), this.props.delay);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      this.setState({ props: prevProps });
    }
  }

  setshow() {
    this.setState({ show: true });
  }

  combineDateAndTime = function(start, end) {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();

    var dateString = "" + year + "-" + month + "-" + day;
    var begin = new Date(dateString + " " + start);
    var complete = new Date(dateString + " " + end);

    if (date > begin && date > complete) {
      return "wrapper done";
    } else if (date > begin) {
      return "wrapper current";
    } else {
      return "wrapper";
    }
  };

  render() {
    let props = this.state.props;
    let wrapper = this.combineDateAndTime(
      props.info.start,
      props.info.delivered
    );
    return (
      <div className={this.state.show ? "orderline show" : "orderline"}>
        <div className={wrapper}>
          {/*
          <div className="blocks firstblock">
            {props.info.user_fullname}
          </div>
          */}
          <div className="blocks secondblock">
            {props.info.order_delivery_timetext}
          </div>
          <div className="blocks thirdblock">{props.info.order_id}</div>
          <div className="blocks fourthblock">
            {" "}
            {props.info.customer_company_name}{" "}
          </div>
          <div className="blocks fifthblock">{props.info.city}</div>
          <div className="blocks sixthblock">{props.info.zipcode}</div>
          <div className="blocks tenthblock">
            {props.info.order_loading_time}
          </div>
          <div className="blocks eighthblock">{props.info.start}</div>
          <div className="blocks seventhblock">{props.info.delivered}</div>
          <div className="blocks ninethblock">
            {wrapper === "wrapper done" ? <Checkmark key="img" /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Newline;
