import React, { Component } from "react";

import { ReactComponent as Zonnig } from "./SVG/Sun.svg";
import { ReactComponent as Bliksem } from "./SVG/Cloud-Lightning.svg";
import { ReactComponent as Regen } from "./SVG/Cloud-Rain.svg";
import { ReactComponent as Buien } from "./SVG/Cloud-Drizzle.svg";
import { ReactComponent as Hagel } from "./SVG/Cloud-Hail.svg";
import { ReactComponent as Mist } from "./SVG/Cloud-Fog.svg";
import { ReactComponent as Sneeuw } from "./SVG/Cloud-Snow.svg";
import { ReactComponent as Bewolkt } from "./SVG/Cloud.svg";
import { ReactComponent as Halfbewolkt } from "./SVG/Cloud.svg";
import { ReactComponent as Zwaarbewolkt } from "./SVG/Cloud.svg";
import { ReactComponent as Nachtmist } from "./SVG/Cloud-Fog-Moon.svg";
import { ReactComponent as Helderenacht } from "./SVG/Moon.svg";
import { ReactComponent as Wolkennacht } from "./SVG/Cloud-Moon.svg";

var XMLParser = require("react-xml-parser");

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = { weer: [] };
  }

  componentDidMount() {
    this.loadData();
    this.svgtrim();
  }

  loadData() {
    fetch("https://data.buienradar.nl/1.0/feed/xml")
      .then(response => response.text())
      .then(text => {
        let xml = new XMLParser().parseFromString(text);
        xml = xml.children[0].children[7].children[0].children[32].children;
        let temperatuur = Math.round(
          xml.filter(item => item.name === "temperatuurGC")[0].value
        );
        let image = xml.filter(item => item.name === "icoonactueel")[0].value;
        let array = { temp: temperatuur, image: image };
        this.setState({
          weer: array
        });
      })
      .catch(error => console.error("Error:", error));
  }

  svgtrim() {
    var svg = document.getElementsByTagName("svg")[0];
    if (svg !== undefined) {
      var bbox = svg.getBBox();
      var viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
      svg.setAttribute("viewBox", viewBox);
    }
  }

  render() {
    return (
      <div className="weather">
        <span>{this.state.weer.temp}°</span>
        {(() => {
          switch (this.state.weer.image) {
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/a.png":
              return <Zonnig />;
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/s.png":
              return <Bliksem />;
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/m.png":
              return <Regen />;
            case "buien":
              return <Buien />;
            case "hagel":
              return <Hagel />;
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/d.png":
              return <Mist />;
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/t.png":
              return <Sneeuw />;
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/c.png":
              return <Bewolkt />;
            case "https://www.buienradar.nl/resources/images/icons/weather/30x30/b.png":
              return <Halfbewolkt />;
            case "zwaarbewolkt":
              return <Zwaarbewolkt />;
            case "nachtmist":
              return <Nachtmist />;
            case "helderenacht":
              return <Helderenacht />;
            case "wolkennacht":
              return <Wolkennacht />;
            default:
              return <Zonnig />;
          }
        })()}
      </div>
    );
  }
}

export default Weather;
