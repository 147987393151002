import React, { Component } from "react";
import Time from "./time";
import News from "./news";
import Weather from "./weather";

import "./footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { key: 0 };
  }

  componentDidMount() {
    let intervalId = setInterval(this.keyinterval.bind(this), 1000 * 60 * 10);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  keyinterval() {
    this.setState({ key: this.state.key + 1 });
  }

  render() {
    return (
      <div className="footer" key={this.state.key}>
        <News />
        <Weather />
        <Time />
      </div>
    );
  }
}

export default Footer;
