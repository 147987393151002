import React, { Component } from "react";

import { api } from "../../config";
class News extends Component {
  constructor(props) {
    super(props);
    this.state = { news: [] };
  }
  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  loadData() {
    fetch(api + "/plugins/delibert/getCastNews")
      .then((res) => res.json())
      .then((response) => {
        this.setState({ news: response });
        let intervalId = setInterval(this.pageswitch.bind(this), 1000 * 5);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });
      })
      .catch((error) => console.error("Error:", error));
  }
  showLine() {
    return (
      <div className="news">
        <div className="item show" key={this.state.news[0].title}>
          {this.state.news[0].title}
        </div>
      </div>
    );
  }

  noLines() {
    return <div className="news" />;
  }

  pageswitch() {
    let ara = this.state.news;
    ara.unshift(ara.pop());
    this.setState({ news: ara });
  }

  render() {
    if (this.state.news.length > 0) {
      return this.showLine();
    } else {
      return this.noLines();
    }
  }
}

export default News;
