import React, { Component } from "react";
class Newline extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false, props: this.props };
  }
  componentDidMount() {
    let intervalId = setInterval(this.setshow.bind(this), this.props.delay);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      this.setState({ props: prevProps });
    }
  }

  setshow() {
    this.setState({ show: true });
  }

  styler = function (start, end, id) {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();

    var dateString = "" + year + "-" + month + "-" + day;
    var begin = new Date(dateString + " " + start);
    var complete = new Date(dateString + " " + end);

    if (date > begin && date > complete) {
      return "wrapper done";
    } else if (date > begin) {
      return "wrapper current";
    } else {
      return "wrapper";
    }
  };

  render() {
    let props = this.state.props;
    let wrapper = this.styler(
      props.info.start,
      props.info.delivered,
      props.info.order_id
    );
    return (
      <div className={this.state.show ? "orderline show" : "orderline"}>
        <div className={wrapper}>
          <div className="blocks firstblock">{props.info.order_id}</div>
          <div className="blocks sevenblock">{props.info.keuken}</div>{" "}
          <div className="blocks secondblock">
            {props.info.customer_company_name}
          </div>
          <div className="blocks thirdblock">
            {props.info.order_delivery_timetext
              .replace(".", ":")
              .replace(".", ":")
              .replace(";", ":")
              .replace(";", ":")}
          </div>
          <div className="blocks fourthblock">
            {Math.abs(Math.round(parseFloat(props.info.colli)))}
          </div>
          <div className="blocks fifthblock">
            {props.info.voltooid === "N" ? "" : "Voltooid"}
          </div>
          <div className="blocks sixthblock">
            {" "}
            {props.info.voltooid === "N" ? props.info.perc : "100"}%
          </div>
        </div>
      </div>
    );
  }
}

export default Newline;
