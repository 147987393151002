import React, { Component } from "react";

class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.Calendar();
    let intervalId = setInterval(this.Calendar.bind(this), 1000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }
  Calendar() {
    var time = new Date();
    var locale = "nl-NL";

    var DD = time.getDate();
    var DDD = time.toLocaleString(locale, { weekday: "long" });
    var MMM = time.toLocaleString(locale, { month: "long" });

    var h = time.getHours();
    var m = time.getMinutes();
    //var s = time.getSeconds();
    m = this.checkTime(m);
    // s = this.checkTime(s);
    let timeformat = h + ":" + m;

    this.setState({ date: MMM, day: DDD, daynumber: DD, time: timeformat });
  }
  checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    } // add zero in front of numbers < 10
    return i;
  }
  render() {
    return (
      <div className="time">
        <div className="dateformat">
          <div className="day">{this.state.day}</div>
          <div className="date">
            {this.state.daynumber}&nbsp;
            {this.state.date}
          </div>
        </div>
        <div className="timeformat">{this.state.time}</div>
      </div>
    );
  }
}

export default Time;
