import React, { Component } from "react";
import Newline from "./newline";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = { lines: this.props.data };
  }

  showLines() {
    let showline = 0;
    return (
      <div className="page show">
        {this.state.lines.map(line => {
          showline = showline + 200;
          return <Newline key={line.order_id} info={line} delay={showline} />;
        })}
      </div>
    );
  }

  noLines() {
    return <div className="page" />;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ lines: nextProps.data });
  }

  render() {
    if (this.props.show === true) {
      return this.showLines();
    } else {
      return this.noLines();
    }
  }
}

export default Page;
